import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout";
import CourseCard from "../common/CourseCard/CourseCard";
import Section from "../common/Section/Section";

import { FreeData } from "../utils/content";

const links = ["コース", "フリーコース"];

const FreeCourse = () => {
  const data = useStaticQuery(graphql`
    {
      prismicFreeCourse {
        id
        data {
          free_image {
            url
          }
          free_text {
            text
            html
          }
        }
      }
    }
  `);

  const fullFreeData = {
    ...FreeData,
    image_url: data.prismicFreeCourse.data.free_image.url,
    text: data.prismicFreeCourse.data.free_text.text,
  };

  return (
    <>
      <Layout
        links={links}
        title="Other"
        subTitle="その他のコース"
        seoTitle="その他のコース"
        seoDescrption="フリーコースの総詳細ページです。"
        seoKeywords="所沢　水泳,狭山市　水泳, スイミング, 埼玉"
      >
        <Section>
          <Container>
            <CourseCard page={fullFreeData} />
          </Container>
        </Section>
      </Layout>
    </>
  );
};

export default FreeCourse;
